import React from 'react';

export const BeforeClock = () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.2 51.4C40.1176 51.4 51.4 40.1176 51.4 26.2C51.4 12.2824 40.1176 1 26.2 1C12.2824 1 1 12.2824 1 26.2C1 40.1176 12.2824 51.4 26.2 51.4Z"
      fill="#00A499"
      stroke="#00A499"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.59998 26.2C1.59998 12.64 12.64 1.59998 26.2 1.59998C39.76 1.59998 50.8 12.64 50.8 26.2"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.2 26.2C47.2 37.78 37.78 47.2 26.2 47.2C14.62 47.2 5.20001 37.78 5.20001 26.2"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2 46.6C37.4666 46.6 46.6 37.4666 46.6 26.2C46.6 14.9334 37.4666 5.79999 26.2 5.79999C14.9334 5.79999 5.79999 14.9334 5.79999 26.2C5.79999 37.4666 14.9334 46.6 26.2 46.6Z"
      fill="white"
      stroke="#00A499"
      strokeMiterlimit="10"
    />
    <path
      d="M51.4 26.2C51.4 40.12 40.12 51.4 26.2 51.4C12.28 51.4 1 40.12 1 26.2"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.79999 26.2C5.79999 14.92 14.92 5.79999 26.2 5.79999C37.48 5.79999 46.6 14.92 46.6 26.2"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2 10.3V14.5C32.68 14.5 37.9 19.72 37.9 26.2C37.9 32.68 32.68 37.9 26.2 37.9C19.72 37.9 14.5 32.68 14.5 26.2C14.5 24.1 15.1 22.06 16.06 20.38L12.4 18.28C11.02 20.62 10.24 23.32 10.24 26.2C10.3 34.96 17.44 42.1 26.2 42.1C34.96 42.1 42.1 34.96 42.1 26.2C42.1 17.44 34.96 10.3 26.2 10.3Z"
      fill="#FFA1A1"
      stroke="#FFA1A1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.16 21.52L10.36 17.08L17.74 14.02L18.16 21.52Z"
      fill="#FFA1A1"
      stroke="#FFA1A1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M26.2 8.79999V11.8" stroke="#00404D" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.5 11.14L19 13.72"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.14 17.5L13.72 19"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.79999 26.2H11.8" stroke="#00404D" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.14 34.9L13.72 33.4"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 41.26L19 38.68"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M26.2 43.6V40.6" stroke="#00404D" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M34.9 41.26L33.4 38.68"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.26 34.9L38.68 33.4"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M43.6 26.2H40.6" stroke="#00404D" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M41.26 17.5L38.68 19"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9 11.14L33.4 13.72"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.62 11.38L26.2 27.4V15.4"
      stroke="#00404D"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
