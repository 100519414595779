import React from 'react';

export const LabeledBox = () => (
  <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.06667 1H43.9333C45.62 1 47 2.02857 47 3.28571V46.7143C47 47.9714 45.62 49 43.9333 49H4.06667C2.38 49 1 47.9714 1 46.7143V3.28571C1 2.02857 2.38 1 4.06667 1Z"
      fill="#FFEABF"
      stroke="#FFEABF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 1H43.9333C45.62 1 47 2.02857 47 3.28571V46.7143C47 47.9714 45.62 49 43.9333 49H35.5"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.0046 9.99535L10.0594 10.8711L11.4023 42.1118L38.3474 41.2361L37.0046 9.99535Z"
      fill="white"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6057 18.4537C19.5866 18.3996 22.5812 18.2078 25.5622 18.1537"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0337 24.2329C21.3185 24.022 26.4466 23.6781 31.7314 23.4671"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2834 32.9842C17.3509 32.3453 18.4361 31.5281 19.3727 30.5201C19.7403 30.1882 19.9947 29.3089 19.4963 29.2715C18.1495 29.3504 16.8805 30.3336 16.407 31.7356C18.5846 31.7189 20.9812 31.1795 23.0809 30.2586C21.8649 30.7066 20.7621 31.7022 19.8255 32.7102C20.6739 32.594 21.5222 32.4778 22.2221 32.1708C26.001 31.1959 29.7798 30.2209 33.3748 29.4118"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 19L8.99258 46.9824" stroke="#00404D" strokeLinecap="round" />
  </svg>
);
