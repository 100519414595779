#import "@portal/fragments/address.gql"
#import "@portal/fragments/address_details.gql"
#import "@portal/fragments/contact.gql"
#import "@portal/fragments/estimation_category.gql"
#import "@portal/fragments/item_pricing.gql"
#import "@portal/fragments/labor_cost_details.gql"
#import "@portal/fragments/marketing/coupon.gql"
#import "@portal/fragments/order.gql"
#import "@portal/fragments/partnerships/partner.gql"
#import "@portal/fragments/pickup_inventory.gql"
#import "@portal/fragments/retail/order.gql"
#import "@portal/fragments/return_inventory.gql"
#import "@portal/fragments/room_category.gql"
#import "@portal/fragments/availability/waitlist_request.gql"

query OrderDetails($orderID: ID!) {
  estimationCategories {
    ...estimationCategory
  }
  roomCategories {
    ...roomCategory
  }
  account {
    customer {
      id
      name
      phone
    }
  }
  order(orderID: $orderID) {
    dropOffAppointment {
      id
      expectedAt
      status
      facility {
        fulfiller {
          ... on SelfStorage__Facility {
            id
          }
          ... on DropOff__ThirdPartySelfStorageFacility {
            id
            operator
          }
        }
        address {
          ...address
        }
      }
    }

    ...order
    movers
    account {
      customer {
        id
        firstName
        name
        phone
        email
      }
    }
    address {
      ...address
      details {
        ...details
      }
    }
    contact {
      ...contact
    }
    services {
      type
      subtype
      ...pickupInventory
      ...returnInventory
    }
    permissions {
      id
      active
      locked
      cancelable
      relocatable
      reschedulable
      changeablePickupInventory
      changeableReturnInventory
      chargeForCancellation
      cancellationFeeDisclaimer
      chargeLateFee
    }
    laborRate {
      id
      amount
    }
    laborCostDetails {
      ...laborCostDetails
    }
    coupon {
      ...Account__Marketing__Coupon
    }
    perItemPricingInfo {
      ...itemPricing
    }
    accountCancelIntent {
      reason
    }
    accountPackages {
      amount
      name
    }
    coiRequests {
      id
      state
      createdAt
      certificateOfInsurance {
        id
        url
      }
    }
    movingOperation {
      id
      originAddress {
        ...address
      }
      destinationAddress {
        ...address
      }
      movingConfiguration {
        id
        travelRateMultiplier
      }
    }
    retailDelivery {
      ...retailOrder
    }
    retailDeliveryItems {
      id
      quantity
    }
    shipped
    bookingPartner {
      ...Partnerships__Partner
    }
    facilityWarehouse {
      id
      accessHours
      accessInstructions
    }
    waitlistRequests {
      ...availabilityWaitlistRequest
    }
    pickupVehicleKind
    formattedArrivalWindow
  }
}
