import React from 'react';

export const WomanWithBoxes: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="184" height="184" fill="none" viewBox="0 0 184 184">
    <g clipPath="url(#clip0)">
      <path
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M89.836 148.282H46.542v34.636h43.294v-34.636zM46.542 148.282H24.894v34.636h21.648v-34.636z"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M72.518 156.941h-8.659c-.866 0-1.515-.65-1.515-1.516 0-.865.65-1.515 1.515-1.515h8.659c.866 0 1.515.65 1.515 1.515-.216.866-.65 1.516-1.515 1.516z"
      ></path>
      <path
        fill="#F79C94"
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M75.332 112.349H32.038v34.635h43.294v-34.635z"
      ></path>
      <path
        fill="#F79C94"
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M82.476 112.349h-8.66v34.635h8.66v-34.635z"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M58.014 121.007h-8.658c-.866 0-1.516-.649-1.516-1.515 0-.866.65-1.515 1.516-1.515h8.658c.866 0 1.516.649 1.516 1.515 0 .866-.65 1.515-1.516 1.515z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M46.541 148.282v33.12M46.541 112.349h35.935M73.817 146.984v-34.635M82.476 148.282H32.038"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M26.193 182.918l12.34-70.569v35.934h-5.846v34.635h-6.494z"
      ></path>
      <path
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M26.777 96.546l-14.769 83.78 14.283 2.518 14.769-83.78-14.283-2.518z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M21.864 180.104l14.72-83.774M1.083 182.918h181.835"
      ></path>
      <path
        fill="#F79C94"
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M98.494 45.675l2.382 5.411.432 24.245h22.513l3.464-20.565c0-1.298-.65-2.597-1.515-3.68l-11.69-14.503c-.866-1.082-2.164-2.165-3.463-2.814l-1.732-.866c-2.381-1.299-5.412-.866-7.144.866l-1.731 1.515c-2.382 2.598-3.247 6.71-1.516 10.39z"
      ></path>
      <path
        fill="#fff"
        d="M99.36 80.31c-2.38 5.628-3.463 11.473-3.463 17.534l2.381 30.09-3.463 4.113c-2.382 3.03-4.33 6.494-5.629 10.174L81.61 164.95h8.226l19.915-32.903c.866-1.516 1.732-3.248 2.165-4.979l9.308-32.254"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M99.36 80.31c-2.38 5.628-3.463 11.473-3.463 17.534l2.381 30.09-3.463 4.113c-2.382 3.03-4.33 6.494-5.629 10.174L81.61 164.95h8.226l19.915-32.903c.866-1.516 1.732-3.248 2.165-4.979l9.308-32.254M124.254 80.31l-.649 6.494 12.988 31.605c.65 1.515.866 3.03 1.083 4.546l3.68 41.779h-8.01l-7.36-24.029c-.433-1.515-.866-3.03-.866-4.762l-.649-6.927"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M81.61 165.167h8.226l-2.382 5.195 4.113 6.278 9.092 3.68c0 1.515-1.082 2.597-2.598 2.597H79.228v-5.628c0-1.299.217-2.381.434-3.463l1.948-8.659zM133.562 164.734l5.845 18.183h2.598l15.586-5.628c1.299-.649 1.732-2.165 1.299-3.464l-9.525-.649-6.278-3.031-1.082-5.411h-8.443z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M121.44 18.832c.217 0 .433-.216.65-.216.866-.433 1.082-1.515.433-2.165-.65-.65-1.299-1.082-1.732-1.948l.216-2.381a123.59 123.59 0 00-.649-3.68M114.296 23.378c.866 2.165 3.464 2.165 3.464 2.165h1.732c.866 0 1.515-.65 1.515-1.516l.216-2.598c-.865.217-1.731 0-2.597-.432M118.842 25.543h-1.298c-1.299 0-2.382.866-2.815 1.948l-1.298 2.815"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M101.092 3.68s-5.195 4.978-3.68 14.503c.216 1.082.433 2.165.866 3.03 4.113-.216 7.576-1.731 10.607-3.68-.217-.432-.217-1.082-.217-1.515 0-1.082.433-1.948 1.299-2.597 1.082-.866 2.381-.433 3.464.433 3.247-3.464 4.978-7.577 4.978-11.04-5.628-2.598-12.122-2.381-17.317.866z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M118.41 13.204v.866"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M97.195 21.43c-3.68.65-7.36 3.247-9.957 6.927-2.598 3.68-3.897 8.442-3.897 13.205 6.494-2.165 12.556-10.39 13.854-20.132z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M108.668 15.369c-.216 1.515.433 3.247 1.732 4.113"
      ></path>
      <path
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M116.028 4.112c-.216 1.299-1.298 2.381-2.381 3.03-1.082.65-2.381 1.3-3.68 1.732-1.948.866-3.896 1.949-5.412 3.464a11.04 11.04 0 00-3.03 5.628"
      ></path>
      <path
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M95.897 20.132l3.03 2.597"
      ></path>
      <path
        fill="#00A499"
        stroke="#00A499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M139.191 117.543l-15.153-37.017v-.649H99.36c-2.597 6.061-6.494 18.616-6.494 37.882h46.325v-.216z"
      ></path>
      <path
        fill="#00404D"
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M122.955 75.332h-21.43s-.866 1.515-1.948 4.329h24.894l-1.516-4.33z"
      ></path>
      <path
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M169.314 45.808l-46.171 3.747 2.801 34.521 46.172-3.747-2.802-34.52z"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M141.139 56.715l11.473-.866M172.095 80.31l-46.109 3.68"
      ></path>
      <path
        fill="#fff"
        d="M133.347 73.384l2.597 1.082c.65.217 1.299.65 1.948 1.3l2.815 2.38c.649.65 1.515.866 2.381 1.083l6.927.866-.217 2.164-.216 1.732c-.217 1.082-1.299 1.948-2.598 1.948l-6.277-.433c-1.083 0-2.382-.433-3.248-1.298l-5.844-4.33 1.732-6.494z"
      ></path>
      <path fill="#F79C94" d="M109.751 44.377l6.278 14.936 17.317 14.07-1.732 6.495-13.854-7.793"></path>
      <path
        stroke="#F79C94"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M109.751 44.377l6.278 14.936 17.317 14.07-1.732 6.495-13.854-7.793"
      ></path>
      <path
        stroke="#00404D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M111.699 48.922l4.33 10.39 9.308 7.36M149.582 83.99c-.217 1.083-1.299 1.95-2.381 1.95l-6.495-.65c-1.082 0-2.164-.433-3.03-1.083l-6.061-4.329-20.132-11.473c-2.165-1.082-3.897-3.03-4.762-5.195l-4.979-10.824"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H184V184H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
