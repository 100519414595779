import React from 'react';

const ID = 'mover-with-box';

export const MoverWithBox: React.FC<{ title?: string }> = ({ title = 'Mover With Box' }) => (
  <svg aria-labelledby={ID} width="79" height="125" viewBox="0 0 79 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <g clipPath="url(#clip0)">
      <path
        d="M34.5145 63.7138L48.4205 54.6689L48.4872 55.7996C48.754 60.0559 48.7206 60.6878 48.2538 70.3644L41.6176 94.8719L28.3785 89.5182L34.5145 63.7138Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1733 109.271L14.739 111.698"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8383 114.592L20.3086 122.938C21.109 123.703 22.1427 124.135 23.2432 124.135H26.6113L26.578 121.176L23.1432 119.713C23.1098 119.679 23.0765 119.646 23.0765 119.613L22.2761 114.06"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1385 113.261L21.242 121.275C22.0423 122.04 23.0761 122.472 24.1766 122.472H26.5443L26.5776 124.134H23.2095C22.109 124.134 21.0419 123.702 20.2749 122.937L11.8046 114.591L13.1385 113.261Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8383 114.591L20.3086 122.937C21.109 123.702 22.1427 124.134 23.2432 124.134H26.6113L26.578 121.175"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.211 68.2702L26.6108 67.2061L28.3115 58.7598H41.684L45.4856 61.7858L44.3184 68.3034L48.02 94.0081L33.0802 98.0649L27.211 68.2702Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6745 106.045L28.3783 89.5186L37.9824 94.8723L22.2423 114.093L17.5403 113.095L17.1735 109.271"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7822 60.3887L37.6155 61.619C37.3154 63.7805 35.4479 65.3766 33.2469 65.3766H32.2132"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4458 59.9893L27.9452 67.6042"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1213 60.5884L75.0654 58.6264L73.3647 40.1377L48.4206 42.0996L50.1213 60.5884Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1213 60.5889L75.0654 58.627"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2918 41.2347L59.6586 44.9923L62.8267 44.7263L62.4932 41.002"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.321 41.9326L75.2651 39.9707"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7457 19.3213C32.9804 20.6514 36.2151 21.9815 39.4832 23.2119C42.4178 25.8722 46.6529 29.7628 47.4866 38.1093L48.6538 59.9898L27.4447 58.693L29.0453 51.8761C24.7101 36.6129 25.0436 28.2664 29.7457 19.3213Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.678 48.3516C28.3783 50.513 29.2786 52.5747 30.4458 54.5699"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6786 33.3535L31.0796 35.1824"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4176 33.9854C42.7848 35.7478 43.6185 38.541 44.2854 41.9993L58.625 55.6664L59.6587 55.7329C60.2924 55.7661 60.8926 55.8991 61.4595 56.1319L61.5262 56.1652C62.0931 56.3979 62.7267 56.531 63.327 56.5642L65.728 56.6972C65.6947 57.3955 65.1611 57.9941 64.4608 58.0938L62.2599 58.4264L63.3937 60.0558C63.6938 60.4881 63.5271 61.0866 63.0602 61.3194L60.3257 62.6495C59.5587 63.0153 58.625 62.8158 58.1248 62.1175L55.7904 59.0914C55.7904 59.0914 35.7484 44.5598 38.1828 46.4553L32.6805 38.807L41.4176 33.9854Z"
        fill="white"
      />
      <path
        d="M63.127 61.3199L60.3924 62.6501C59.6254 63.0158 58.7251 62.8163 58.1915 62.118L55.8572 59.092C53.4561 57.6288 50.855 55.4009 48.5207 53.7715L48.7541 60.6881"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.9932 61.8856C61.2262 62.2513 60.3258 62.0518 59.7922 61.3535L58.7918 60.0566"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8267 61.3194C62.2597 61.3194 61.7262 61.0866 61.3594 60.6211L60.3589 59.3242"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4128 31.126C30.5795 33.7197 31.2131 36.646 32.7471 38.7742L38.2495 46.4224"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.7952 61.4531C67.4955 61.7857 68.1625 62.1514 68.8628 62.484"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2543 120.91L47.8203 118.649L52.0555 119.347L54.3231 115.789L60.5925 116.421L64.8943 115.157L66.6284 118.017"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.0576 121.675L65.7947 116.62L66.6284 118.05L57.6912 123.204C56.5908 123.836 55.3235 124.169 54.0563 124.169H51.2551L50.288 122.639H53.3894C54.6899 122.639 55.9572 122.306 57.0576 121.675Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.6284 118.017L57.6912 123.204C56.5908 123.836 55.3235 124.168 54.0563 124.168H51.2551"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.4321 124.168H1.33352"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.3228 115.789L52.0551 119.347L47.82 118.648L35.3479 97.5326L45.6857 94.6729L52.2885 112.397"
        fill="white"
      />
      <path
        d="M54.3228 115.789L52.0551 119.347L47.82 118.648L35.3479 97.5326L45.6857 94.6729L52.2885 112.397"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4154 21.1503C38.1824 20.4853 38.6826 19.5542 38.916 18.5899C38.149 18.4568 37.4487 18.0246 36.9152 17.4593C36.3816 16.8607 36.0815 16.0959 36.0815 15.3311"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8832 18.5562C40.4501 18.5895 41.017 18.6227 41.5505 18.4232C42.0841 18.257 42.5843 17.7582 42.6177 17.2261C42.4176 16.8603 42.1842 16.3615 41.9841 15.9957C42.0508 15.7297 41.9841 15.4304 42.0174 15.1644C41.5172 15.2642 40.9503 15.1977 40.5168 14.9316"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.1176 13.635C42.7179 13.7347 43.3515 13.1694 43.8184 12.8036L41.7508 10.1434L41.8175 9.31207C41.8508 8.71351 41.6841 8.14821 41.384 7.64941"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4464 18.4902C31.7132 16.8941 31.3463 15.1649 30.4126 13.8348C29.6456 12.7375 28.7786 11.9394 28.345 10.6758C27.9115 9.41215 27.9449 7.91576 28.6785 6.78516"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0481 10.9086C34.9147 10.443 34.4145 10.177 33.9476 10.2435C33.4808 10.31 33.114 10.7091 32.9806 11.1413C32.8472 11.6069 32.9472 12.1057 33.214 12.5047C33.4808 12.9038 33.8476 13.203 34.2478 13.4358C34.648 13.6686 35.1148 13.8348 35.5483 13.9679"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6785 3.35977C29.1453 3.42627 29.7456 3.32651 30.1791 3.39302C30.2791 2.99398 30.4792 2.49519 30.5793 2.09615C31.0795 2.26241 31.7464 1.96314 32.2467 2.1294C32.4468 1.79687 32.6468 1.33133 32.6802 0.93229C33.2471 1.03205 33.8474 1.16506 34.3476 1.43109C34.581 1.16506 34.7477 0.732772 34.9478 0.5C35.348 0.799278 35.9483 1.16506 36.3484 1.46434C36.6152 1.16506 37.0154 0.865784 37.3155 0.566506C37.649 0.865784 38.2493 1.16506 38.5827 1.46434C38.9496 1.29807 39.3831 1.36458 39.7499 1.19832C39.7832 1.5641 39.9833 1.92988 40.0167 2.29567C40.3835 2.26241 40.8837 2.29567 41.2505 2.22916C41.484 2.6282 41.6841 3.26001 41.484 3.65904C41.7508 3.65904 42.1843 3.79206 42.4511 3.79206C42.5511 4.62338 42.3177 5.62098 41.6174 6.11977L41.1172 6.28604L34.9145 6.51881L28.8452 5.95351L28.6785 5.05567C28.7118 4.55688 28.6785 3.85856 28.6785 3.35977Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.181 6.71777L35.2144 10.0431"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2144 7.68294L28.6786 6.81836"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.4857 61.7852L44.3185 68.3028L45.7524 78.4117"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="79" height="125" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
