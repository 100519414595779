import React from 'react';

export const TruckFee: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect x="-2.5" y="2.5" width="26" height="37" stroke="#063D3A" />
      <rect x="-6" y="5" width="27" height="34" fill="#99DBD6" />
      <path d="M23 8C35.7025 8 46 18.2975 46 31V40H42H23V8Z" fill="#99DBD6" />
      <rect x="42" y="32" width="4" height="1" fill="#063D3A" />
      <rect x="42" y="34" width="4" height="1" fill="#063D3A" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="23" y="8" width="23" height="32">
        <path d="M23 8C35.7025 8 46 18.2975 46 31V40H42H23V8Z" fill="#E0E0E0" />
      </mask>
      <g mask="url(#mask0)">
        <rect x="32.5" y="8.5" width="13" height="19" stroke="#063D3A" />
        <rect x="35" y="5" width="14" height="20" fill="white" />
      </g>
      <path d="M23.5 8.50545C35.6955 8.77135 45.5 18.7407 45.5 31V39.5H42H23.5V8.50545Z" stroke="#063D3A" />
      <circle cx="34" cy="39" r="6.5" fill="white" stroke="#063D3A" />
      <circle cx="34" cy="39" r="5" fill="#00A499" />
      <circle cx="34" cy="39" r="2.5" fill="white" stroke="#063D3A" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0H48V48H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
