#import "@shared/fragments/estimation_category.gql"
#import "@shared/fragments/room_category.gql"

query InventoryConfig {
  estimationCategories {
    ...estimationCategory
  }
  roomCategories {
    ...roomCategory
  }
}
