import React from 'react';

export const ColorfulBoxes: React.FC = () => (
  <svg width="74" height="62" viewBox="0 0 74 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3284 44.5443C5.14295 28.7288 7.36114 35.8229 5.76685 28.6371C4.17257 21.4513 27.3136 1.67729 36.7385 9.65871C55.469 25.5207 59.9538 28.7746 48.214 32.6097C40.892 35.0017 32.5898 40.2265 24.804 43.1672C20.7342 44.7044 16.1383 49.9734 13.3284 44.5443Z"
      fill="#A1E6E0"
    />
    <path
      d="M19.2959 46.8394L10.3029 43.2726C9.05864 42.7468 8.25 41.5281 8.25 40.1773V19.5638C8.25 18.2073 9.06497 16.9837 10.3168 16.4614L27.1223 9.45684C27.9509 9.114 28.8817 9.114 29.7103 9.45684L46.5158 16.4614C47.7677 16.9835 48.5831 18.2073 48.5831 19.5638V21.5933"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.89233 17.5871L28.4169 25.7243L47.9415 17.5871"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.468 13.0532L39.1387 21.2543L18.468 13.0532Z" fill="#FFEABF" />
    <path d="M18.468 13.0532L39.1387 21.2543" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.417 25.7245L28.4703 33.9868" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M42.5001 24.5C43.0001 23.8245 44.4885 23.1285 44.4885 23.8245C58.8541 21.5888 60.661 16.6738 67.5751 23.4454C74.8133 30.5344 77.3751 46.2212 67.5751 49.117C48.0988 54.872 37.1275 58.807 39.7527 49.1169C42.2459 39.9142 27.0001 26.5 42.5001 24.5Z"
      fill="#FFD078"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.7061 28.4804C70.7061 27.3988 70.0009 26.4229 68.918 26.0066L54.3824 20.4212C53.6657 20.1478 52.8606 20.1478 52.1439 20.4212L37.6082 26.0066C36.5255 26.4231 35.8206 27.3988 35.8206 28.4804V44.9176C35.8206 45.9947 36.52 46.9665 37.5962 47.3857L52.1318 53.0568C52.8515 53.3353 53.6623 53.3353 54.3819 53.0568L68.9176 47.4742C69.9986 47.0587 70.7037 46.0857 70.7061 45.006V28.4804Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7385 27.5605L52.3452 33.0688L70.247 27.1015"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M44.542 23.4293L61.9848 29.8556L44.542 23.4293Z" fill="#FFEABF" />
    <path d="M44.542 23.4293L61.9848 29.8556" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22.2399 35.7081C30.6922 36.535 37.7871 30.886 43.6164 36.7153C49.4457 42.5446 51.5089 55.4439 43.6164 57.8251C27.9312 62.5575 22.2399 61.528 22.5067 57.8251C23.0693 50.0161 13.7876 34.8812 22.2399 35.7081Z"
      fill="#FFEABF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.201 39.8823V39.8821C46.201 38.9767 45.6568 38.16 44.8211 37.8115L33.6034 33.1359V33.1359C33.0503 32.9071 32.429 32.9071 31.8759 33.1359L20.6582 37.8115V37.8115C19.8226 38.1601 19.2786 38.9769 19.2786 39.8823V53.6419V53.6414C19.2786 54.5431 19.8183 55.3571 20.6489 55.708L31.8666 60.4554V60.4554C32.4219 60.6885 33.0477 60.6885 33.6031 60.4554L44.8208 55.7821V55.7821C45.655 55.4343 46.1991 54.6198 46.201 53.716V39.8823Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7073 38.563L32.74 43.9946L45.7727 38.563"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.0991 35.5365L39.8969 41.0108L26.0991 35.5365Z" fill="#FFEABF" />
    <path d="M26.0991 35.5365L39.8969 41.0108" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M32.7402 43.9947V60.6301V43.9947Z" fill="#FFEABF" />
    <path d="M32.7402 43.9947V60.6301" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M52.3452 33.0688L52.8042 52.8066L52.3452 33.0688Z" fill="#FFEABF" />
    <path d="M52.3452 33.0688L52.8042 52.8066" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
