import React from 'react';

const DEFAULT_WIDTH = 24;
const DEFAULT_HEIGHT = 36;

export const LocationIcon: React.FC<{ body?: string; scale?: number; fill?: string }> = ({
  body,
  scale = 1.0,
  fill = '#00A499',
}) => {
  const width = DEFAULT_WIDTH * scale;
  const height = DEFAULT_HEIGHT * scale;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4013 15.2031C23.7898 14.0635 24 12.8459 24 11.5808C24 5.18491 18.6274 0 12 0C5.37258 0 0 5.18491 0 11.5808C0 12.8035 0.19634 13.9819 0.560252 15.0883C0.536614 15.2877 0.573119 15.4985 0.684578 15.6954L1.09479 16.4202C1.32365 16.8997 1.58547 17.3616 1.87744 17.8031L11.8884 35.4911C12.2851 36.1919 13.3051 36.1604 13.6578 35.4364L23.2991 15.6407C23.3691 15.497 23.4013 15.3481 23.4013 15.2031Z"
        fill={fill}
        transform={`scale(${scale})`}
      />
      {body && (
        <text x={width / 2} y={height / 2} fontWeight="bold" textAnchor="middle" fill="white">
          {body}
        </text>
      )}
    </svg>
  );
};
