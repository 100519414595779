import React from 'react';

export const ThreeStackedBoxes: React.FC = () => (
  <svg width="52" height="83" viewBox="0 0 52 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48.6782 57.7429H18.2803V82.0612H48.6782V57.7429Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2803 57.7429H3.0813V82.0612H18.2803V57.7429Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.5194 63.8224H30.4398C29.8318 63.8224 29.4265 63.4171 29.4265 62.8092C29.4265 62.2012 29.8318 61.7959 30.4398 61.7959H36.5194C37.1273 61.7959 37.5326 62.2012 37.5326 62.8092C37.5326 63.4171 37.1273 63.8224 36.5194 63.8224Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.1716 7.07959H17.7737V31.398H48.1716V7.07959Z"
      fill="#A1E6E0"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0125 13.1592H29.9329C29.325 13.1592 28.9197 12.7539 28.9197 12.146C28.9197 11.538 29.325 11.1327 29.9329 11.1327H36.0125C36.6205 11.1327 37.0258 11.538 37.0258 12.146C37.0258 12.7539 36.6205 13.1592 36.0125 13.1592Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.0391 32.4113H7.64111V56.7296H38.0391V32.4113Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1055 32.4113H37.0259V56.7296H43.1055V32.4113Z"
      fill="#F79C94"
      stroke="#F79C94"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.88 38.4909H19.8004C19.1924 38.4909 18.7871 38.0856 18.7871 37.4776C18.7871 36.8697 19.1924 36.4644 19.8004 36.4644H25.88C26.4879 36.4644 26.8932 36.8697 26.8932 37.4776C26.8932 38.0856 26.4879 38.4909 25.88 38.4909Z"
      fill="#00404D"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7737 57.7429V81.048"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7737 32.4113H43.1053"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.0259 56.7296V32.4113"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1054 57.7429H7.64111"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.1716 7.07959L32.9727 1"
      stroke="#A1E6E0"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.7049 82.0612H1.05493"
      stroke="#00404D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
