import * as React from 'react';
import { range } from 'lodash';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { IPagination as IGQLPagination } from '@shared/types';

const INITIAL_PAGE = 1;
const SURROUNDING_PAGES = 4; // i.e. how many pages to display on either side of the current page.

const List: React.FC = ({ children }) => <ul className="pagination">{children}</ul>;

const Dots: React.FC = () => (
  <li className="page-item disabled">
    <span className="page-link">…</span>
  </li>
);

const Entry: React.FC<{
  text: string;
  page?: number | null;
  current: number;
  url(page: number): string;
}> = ({ text, page, current, url }) => (
  <li className={cn('page-item', !page && 'disabled', current === page && 'active')}>
    <NavLink className="page-link" to={page ? url(page) : '#'}>
      {text}
    </NavLink>
  </li>
);

export const Pagination: React.FC<{
  pagination: IGQLPagination;
  url(page: number): string;
}> = ({ pagination, url }) => {
  const current = pagination.currentPage;
  const total = pagination.totalPages;
  const prev = pagination.prevPage;
  const next = pagination.nextPage;

  const min = Math.max(INITIAL_PAGE, current - SURROUNDING_PAGES);
  const max = Math.min(total, current + SURROUNDING_PAGES);

  return (
    <>
      {total > INITIAL_PAGE && (
        <nav>
          <List>
            <Entry text="‹ Prev" page={prev} current={current} url={url} />

            {min > INITIAL_PAGE && <Dots />}

            {range(min, max + 1).map((page) => (
              <Entry key={page} page={page} text={page.toString()} current={current} url={url} />
            ))}

            {max < total && <Dots />}

            <Entry text="Next ›" page={next} current={current} url={url} />
          </List>
        </nav>
      )}
    </>
  );
};
