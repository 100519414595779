import React from 'react';

export const Lightbulb: React.FC = () => (
  <svg width="23" height="33" viewBox="0 0 23 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9055 25.4626C12.9055 14.2506 12.9055 9.66385 8.44141 1.50965C9.43343 1.00002 17.3696 -0.0192577 20.8417 7.11566C24.1042 13.82 19.6843 19.1771 17.3696 20.8759C16.3776 21.8951 17.3696 26.4819 12.9055 25.4626Z"
      fill="#FFD078"
    />
    <path
      d="M21.8325 12.2109C21.8325 5.4088 17.4467 1 11.3553 1C5.26396 1 1 6.29056 1 10.9513C1 15.1082 2.24387 18.1765 4.28934 19.8949C6.23858 21.5324 6.72589 23.5479 6.72589 25.0594V30.476C6.72589 32.2395 10.3807 31.9876 11.3553 31.9876C12.3299 31.9876 16.2284 31.9876 16.2284 30.476V25.4373C16.2284 22.5149 16.5804 21.9103 17.4467 20.9026C19.3959 18.6352 21.8325 15.864 21.8325 12.2109Z"
      stroke="black"
    />
    <path d="M6.74609 25.3572H16.0848" stroke="black" />
    <path d="M6.74609 27.5714H16.0848" stroke="black" />
    <path d="M6.74609 29.7858H16.0848" stroke="black" />
    <path
      d="M9.93717 25.3572V14.5443C9.93718 13.0178 10.0599 11.4913 8.46437 11.4913C7.35976 11.4913 6.74609 12.0001 6.74609 12.7634C6.74609 14.3265 7.1758 14.7988 9.93718 14.7988C13.251 14.7988 13.8647 14.7988 14.3556 14.7988C15.2641 14.7988 16.1966 14.4171 16.0739 12.7634C15.9606 11.2368 14.4513 11.1645 13.7419 11.4913C12.6373 12.0001 12.8828 12.6361 12.8828 15.4348C12.8828 18.2843 12.8828 23.237 12.8828 25.3572"
      stroke="black"
    />
  </svg>
);
