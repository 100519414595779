import React from 'react';

export const PersonBoxes: React.FC = () => (
  <svg width="81" height="65" viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.8466 64.541H55.6727C55.3343 64.541 55.0442 64.2511 55.0442 63.9129C55.0442 63.5747 55.3343 63.2848 55.6727 63.2848H79.8466C80.185 63.2848 80.4751 63.5747 80.4751 63.9129C80.4751 64.2511 80.2092 64.541 79.8466 64.541Z"
      fill="#0A3D3A"
    />
    <path
      d="M48.5175 11.9725C47.2847 11.9 46.076 11.2478 45.3508 10.2331C44.6255 9.21847 44.3596 7.88976 44.6739 6.68185C44.8673 5.98126 45.2057 5.32899 45.3749 4.6284C45.52 3.97613 45.4958 3.2997 45.6167 2.64742C45.7375 1.99515 46.1001 1.31872 46.7045 1.07714C47.3572 0.811397 48.0824 1.1013 48.7834 0.932194C49.1702 0.859719 49.4845 0.642283 49.8471 0.497334C50.2097 0.352385 50.6207 0.279922 50.9591 0.449029C51.37 0.666454 51.5634 1.19793 51.9744 1.39119C52.482 1.63277 53.1589 1.29456 53.6666 1.58445C54.0292 1.77772 54.1742 2.23673 54.1259 2.64742C54.0775 3.05811 53.8841 3.42048 53.7391 3.80701C53.594 4.19355 53.4732 4.60424 53.5699 5.01493C53.6907 5.49809 54.1259 5.86047 54.5126 6.19869C54.8994 6.5369 55.262 6.9959 55.2137 7.50322C55.1653 7.88975 54.8994 8.17965 54.6335 8.46955C54.0533 9.09766 53.4732 9.70162 52.893 10.3297C52.6029 10.6438 52.2886 10.9579 51.9019 11.1028C51.6601 11.1994 51.3942 11.2236 51.1283 11.2477C50.3547 11.3202 49.5812 11.3686 48.7834 11.441"
      fill="#0A3D3A"
    />
    <path
      d="M48.5174 11.586C46.9944 11.4652 45.6648 10.4989 45.1572 9.04941C44.6254 7.52744 45.2781 6.44031 45.689 5.01498C45.9066 4.26607 45.8582 3.51717 46.0033 2.76827C46.2208 1.77778 46.7527 1.36709 47.7438 1.36709C48.324 1.36709 48.7833 1.39125 49.3393 1.17383C49.6294 1.05304 49.8953 0.88393 50.1854 0.811456C50.693 0.666506 50.9589 0.956405 51.2732 1.29462C51.5874 1.65699 51.9259 1.87442 52.4093 1.89858C52.6753 1.92273 52.9653 1.82609 53.2313 1.87441C53.8356 2.01936 53.7873 2.57499 53.6664 3.034C53.328 4.1936 52.8203 5.01498 53.7873 6.02963C54.1982 6.46448 55.1168 6.94764 54.7784 7.7207C54.5125 8.34882 53.7147 8.92862 53.2554 9.41178C52.6269 10.0882 52.1434 10.7405 51.1765 10.8371C50.4029 10.9096 49.6294 10.9579 48.8316 11.0304C48.324 11.0787 48.324 11.8518 48.8316 11.8035C50.137 11.7068 51.8292 11.9001 52.8686 10.9579C53.4005 10.4747 53.884 9.89494 54.3916 9.36346C54.8026 8.92861 55.3586 8.46961 55.5761 7.88981C56.0838 6.53695 54.4883 6.1021 54.0773 5.08745C53.5939 3.95201 55.1652 2.79243 54.2949 1.60867C54.0048 1.22214 53.5697 1.10134 53.1104 1.10134C52.6994 1.10134 52.361 1.19798 51.9984 0.90808C51.7567 0.714814 51.6358 0.424912 51.3699 0.255804C50.9589 -0.0340943 50.4754 -0.0582408 50.0161 0.0867086C49.2667 0.304133 48.7591 0.642336 47.913 0.618178C46.7285 0.569861 45.8099 0.956405 45.3989 2.14016C45.1814 2.76827 45.2055 3.4447 45.1088 4.09697C44.988 4.99082 44.5528 5.73973 44.3353 6.60943C43.6101 9.4601 45.689 12.1417 48.5657 12.3832C49.025 12.4074 49.025 11.6343 48.5174 11.586Z"
      fill="#0A3D3A"
    />
    <path
      d="M52.4326 14.4366C51.5139 12.9629 51.2722 11.1269 51.7315 9.45998C51.9249 8.78355 52.2392 8.13128 52.2392 7.40653C52.2392 6.87505 52.07 6.34356 51.8524 5.8604C51.4898 5.08734 50.9579 4.38675 50.2086 3.97606C50.0635 3.90359 49.8701 3.83111 49.6526 3.80695C48.3472 3.61369 47.066 4.33844 46.5341 5.52219C46.4616 5.71546 46.3891 5.90872 46.3407 6.10198C46.1232 6.99583 46.2199 7.96217 46.655 8.78355C47.0176 9.48414 47.5978 10.0639 48.1538 10.6437C48.7098 11.2235 49.2416 11.8516 49.5075 12.6005C49.7734 13.3494 49.6767 14.2916 49.0966 14.8231"
      fill="#FAE6D3"
    />
    <path
      d="M52.6985 14.2918C51.9491 13.0114 51.6349 11.5136 51.9008 10.0399C52.0942 8.95278 52.6985 7.93815 52.4809 6.80271C52.1425 4.96668 50.5712 3.1548 48.5648 3.56549C46.5825 3.97619 45.6156 6.0538 46.0265 7.93815C46.2683 9.04943 46.9935 9.87081 47.7429 10.668C48.6856 11.6585 50.0877 13.3013 48.8549 14.63C48.5889 14.8957 49.0241 15.3306 49.2658 15.0407C50.5712 13.6395 49.6042 11.8276 48.5164 10.6439C47.622 9.67753 46.6309 8.75952 46.51 7.35834C46.4133 6.19875 46.8726 4.99083 47.9363 4.41103C49.1208 3.75876 50.3295 4.14529 51.103 5.20825C51.9249 6.31953 52.0458 7.43082 51.6107 8.73537C50.958 10.7405 51.0789 12.7456 52.1425 14.5817C52.3842 14.8957 52.8919 14.6058 52.6985 14.2918Z"
      fill="#0A3D3A"
    />
    <path
      d="M60.4824 22.1673L59.1286 34.9228C59.4429 35.7201 59.4429 36.5898 59.1286 37.387C58.8385 38.1117 58.5726 39.1747 58.7419 40.4792C58.7902 40.9141 58.4034 41.2523 57.9925 41.1315L53.3511 39.8511L54.173 21.6358L58.4034 20.7419"
      fill="#FAE6D3"
    />
    <path
      d="M58.1383 41.4455C58.0658 41.4455 57.9691 41.4455 57.8966 41.4214L53.0134 40.0685L53.8595 21.37L58.3317 20.4279L58.4526 21.0318L54.4639 21.8774L53.6661 39.5854L58.0658 40.7933C58.1625 40.8174 58.2592 40.7932 58.3317 40.7208C58.4042 40.6483 58.4284 40.5758 58.4284 40.4792C58.2592 39.078 58.5493 37.9426 58.8393 37.242C59.1294 36.5173 59.1294 35.72 58.8393 35.0194L58.8152 34.947V34.8745L60.1689 22.1189L60.7974 22.1914L59.4437 34.8503C59.7579 35.6959 59.7579 36.6139 59.4195 37.4836C59.1536 38.1117 58.8877 39.1263 59.0569 40.4067C59.1052 40.6966 58.9844 41.0107 58.7426 41.2039C58.5734 41.373 58.3559 41.4455 58.1383 41.4455Z"
      fill="#0A3D3A"
    />
    <path
      d="M41.2156 22.6503L41.8683 34.4154L41.1915 36.493C41.0464 36.9762 41.1673 37.4835 41.5057 37.8459L41.5782 37.9183L43.1254 39.7544L43.488 39.7785L46.0021 37.3869L44.9142 24.2931L42.2309 21.998L41.2156 22.6503Z"
      fill="#FAE6D3"
    />
    <path
      d="M43.5857 40.117L42.9572 40.0687L41.2892 38.0636C40.8541 37.6287 40.709 36.9764 40.9024 36.3966L41.5551 34.3674L40.9024 22.4815L42.2561 21.6118L45.2054 24.1484L46.3173 37.5079L43.5857 40.117ZM43.2714 39.4647H43.3681L45.6647 37.2663L44.601 24.4383L42.2078 22.3607L41.5551 22.8197L42.2078 34.4398L41.5068 36.5658C41.3859 36.9281 41.4826 37.3388 41.7485 37.6046L41.821 37.7012L43.2714 39.4647Z"
      fill="#0A3D3A"
    />
    <path
      d="M57.5093 20.2592L57.5577 34.4401L56.5907 34.6092L57.0984 39.8274C56.8083 43.1129 55.1887 47.1473 55.1887 49.1041L55.7688 61.2074L56.2765 62.9468C56.4699 63.6474 55.9622 64.348 55.237 64.348L42.4974 64.5896C42.3523 63.9131 42.6424 63.2125 43.2226 62.8502L47.2355 60.6518C47.0904 59.6854 45.5191 51.0851 44.6488 46.8333L44.2621 37.6531L43.6577 35.0923L43.561 23.3031L40.6118 23.2065C41.0711 13.2291 44.8664 15.1618 49.6287 13.2291L50.4506 14.123C50.789 14.5095 51.4175 14.437 51.6834 14.0022L52.0944 13.2774C55.0436 13.7365 62.1507 13.4707 60.4827 23.6413L57.7511 24.4627"
      fill="white"
    />
    <path
      d="M42.2078 65L42.1353 64.686C41.9661 63.8646 42.3287 62.9949 43.0298 62.5359L43.0539 62.5117L46.825 60.4583C46.535 58.7189 45.1087 50.8675 44.2868 46.9055V46.833L43.9 37.7012L43.2957 35.1404L43.199 23.6894L40.2256 23.5928L40.2498 23.2063C40.6365 15.1616 43.2473 14.5335 46.5591 13.7604C47.5019 13.543 48.4689 13.3014 49.5083 12.8907L49.7501 12.7941L50.7412 13.8812C50.8137 13.9778 50.9346 14.002 51.0555 14.002C51.1763 13.9778 51.273 13.9295 51.3456 13.8087L51.9016 12.8665L52.1675 12.9149C52.4334 12.9632 52.7718 12.9873 53.1103 13.0356C55.0683 13.2531 58.0175 13.6155 59.7339 15.9105C61.0635 17.674 61.4261 20.3073 60.8701 23.7378L60.8217 23.9793L57.9209 24.849L57.945 34.7781L57.0022 34.9472L57.4615 39.8272V39.8513C57.3165 41.5665 56.8089 43.4992 56.3496 45.1903C55.9386 46.7364 55.5518 48.1859 55.5518 49.0798L56.132 61.1347L56.6396 62.8258C56.7605 63.2848 56.688 63.7438 56.4221 64.1303C56.1562 64.5169 55.721 64.7343 55.2617 64.7343L42.2078 65ZM43.4407 63.1882C43.1023 63.4056 42.8847 63.7921 42.8605 64.2028L55.2376 63.9612C55.4551 63.9612 55.6727 63.8404 55.7936 63.6713C55.9144 63.5022 55.9628 63.2606 55.8902 63.0674L55.3584 61.2797V61.2314L54.7783 49.1039C54.7783 48.0893 55.165 46.6639 55.6002 44.997C56.0353 43.3542 56.5429 41.4699 56.688 39.8272L56.1562 34.2949L57.1473 34.1258L57.0989 20.259H57.8725L57.8967 24.0277L60.1207 23.3512C60.6041 20.2348 60.2415 17.8915 59.1054 16.3453C57.5824 14.316 54.9475 14.002 53.0136 13.7845C52.7718 13.7604 52.5301 13.7362 52.3125 13.6879L52.0224 14.1711C51.829 14.4851 51.5148 14.7026 51.1522 14.7509C50.7896 14.7992 50.427 14.6542 50.1852 14.3885L49.5567 13.6879C48.5656 14.0745 47.5986 14.2919 46.7525 14.4851C43.5858 15.2341 41.4585 15.7172 41.0717 22.8197L43.9967 22.9164L44.0934 35.0197L44.6978 37.6046L45.0845 46.7364C45.979 51.0849 47.5261 59.5886 47.6711 60.5549L47.7195 60.8207L43.4407 63.1882Z"
      fill="#0A3D3A"
    />
    <path
      d="M43.6094 24.7766C43.3919 24.7766 43.2227 24.6075 43.2227 24.39V21.0562C43.2227 20.8388 43.3919 20.6697 43.6094 20.6697C43.827 20.6697 43.9962 20.8388 43.9962 21.0562V24.39C43.9962 24.5833 43.827 24.7766 43.6094 24.7766Z"
      fill="#0A3D3A"
    />
    <path
      d="M49.1214 35.2372C48.9038 35.2372 48.7346 35.0681 48.7346 34.8748C48.7346 34.6574 48.8797 34.4641 49.0972 34.4641L57.5339 34.0535C57.7515 34.0535 57.9449 34.1984 57.9449 34.4158C57.9449 34.6332 57.7998 34.8265 57.5823 34.8265L49.1214 35.2372Z"
      fill="#0A3D3A"
    />
    <path
      d="M46.6081 64.5167C46.5114 63.8403 46.874 63.1639 47.4783 62.8498L51.322 60.5789C51.322 60.5789 50.9352 57.1243 50.5001 53.718"
      fill="white"
    />
    <path
      d="M46.6073 64.9274C46.4139 64.9274 46.2446 64.7825 46.2205 64.5892C46.0996 63.7437 46.5347 62.9223 47.2841 62.5116L50.9102 60.3615C50.8135 59.516 50.4751 56.5928 50.1125 53.7663C50.0883 53.5489 50.2333 53.3556 50.4509 53.3315C50.6685 53.3073 50.8619 53.4523 50.886 53.6697C51.3212 57.0519 51.7079 60.5065 51.7079 60.5306L51.7321 60.7722L47.6467 63.188C47.1874 63.4296 46.9215 63.9611 46.994 64.4684C47.0182 64.6859 46.8732 64.8791 46.6556 64.9033C46.6556 64.9033 46.6314 64.9274 46.6073 64.9274Z"
      fill="#0A3D3A"
    />
    <path d="M49.7742 39.5612L49.4358 46.6153L50.1368 50.7464" fill="white" />
    <path
      d="M50.1369 51.133C49.9435 51.133 49.7743 50.988 49.7501 50.7947L49.0491 46.6395L49.3875 39.537C49.3875 39.3196 49.5809 39.1505 49.7985 39.1747C50.016 39.1747 50.1852 39.3679 50.1611 39.5854L49.8226 46.5912L50.4995 50.674C50.5237 50.8914 50.4028 51.0846 50.1852 51.133C50.1611 51.133 50.1611 51.133 50.1369 51.133Z"
      fill="#0A3D3A"
    />
    <path
      d="M40.6117 64.9757H32.9244C32.586 64.9757 32.2959 64.6858 32.2959 64.3476C32.2959 64.0094 32.586 63.7195 32.9244 63.7195H40.6117C40.9501 63.7195 41.2402 64.0094 41.2402 64.3476C41.2402 64.6858 40.9501 64.9757 40.6117 64.9757Z"
      fill="#0A3D3A"
    />
    <path d="M33.4578 37.1697H6.26221V64.3477H33.4578V37.1697Z" fill="#0A3D3A" />
    <path
      d="M34.0132 64.8792H5.70557V36.614H34.0132V64.8792ZM6.81757 63.792H32.9253V37.7011H6.81757V63.792Z"
      fill="#0A3D3A"
    />
    <path
      d="M27.751 64.3477H0.55542V40.5277C0.55542 38.6675 2.0542 37.1697 3.91559 37.1697H27.751V64.3477Z"
      fill="white"
    />
    <path
      d="M28.3076 64.8792H0V39.3439C0 37.8461 1.23287 36.614 2.73165 36.614H28.3076V64.8792ZM1.112 63.792H27.2198V37.7011H2.73165C1.83722 37.7011 1.112 38.4259 1.112 39.3197V63.792Z"
      fill="#0A3D3A"
    />
    <path
      d="M12.498 49.2248H4.01294V41.5666C4.01294 41.1076 4.37555 40.7452 4.83485 40.7452H12.498V49.2248ZM4.35137 48.9107H12.1837V41.0834H4.83485C4.56894 41.0834 4.35137 41.3009 4.35137 41.5666V48.9107Z"
      fill="#0A3D3A"
      stroke="#0A3D3A"
      strokeMiterlimit="10"
    />
    <path d="M33.1665 18.8335H14.9878V37.0005H33.1665V18.8335Z" fill="#0A3D3A" />
    <path
      d="M33.7219 37.5563H14.4553V18.3021H33.7219V37.5563ZM15.5431 36.445H32.6341V19.3651H15.5431V36.445Z"
      fill="#0A3D3A"
    />
    <path
      d="M29.3465 37.0008H11.1677V21.7569C11.1677 20.1383 12.4731 18.8337 14.0928 18.8337H29.3465V37.0008Z"
      fill="white"
    />
    <path
      d="M29.9026 37.5566H10.636V20.7425C10.636 19.3896 11.7238 18.3025 13.0775 18.3025H29.9026V37.5566ZM11.7238 36.4454H28.8147V19.3655H13.0775C12.3282 19.3655 11.7238 19.9694 11.7238 20.7183V36.4454Z"
      fill="#0A3D3A"
    />
    <path d="M27.2194 37.991H15.4951V63.5987H27.2194V37.991Z" fill="#FAE6D3" />
    <path d="M28.7907 19.4133H23.0132V36.4449H28.7907V19.4133Z" fill="#B9E2E4" />
  </svg>
);
