import React from 'react';

const ID = 'caret-up';

export const CaretUp: React.FC<{ title?: string }> = ({ title = 'Collapse' }) => (
  <svg aria-labelledby={ID} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M7.62204 4.43644C7.82142 4.2062 8.17858 4.2062 8.37796 4.43644L12.4797 9.17267C12.7601 9.4965 12.5301 10 12.1017 10H3.8983C3.46992 10 3.2399 9.4965 3.52033 9.17267L7.62204 4.43644Z"
      fill="black"
    />
  </svg>
);
