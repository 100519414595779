import React from 'react';

export const ItemFee: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="32" y="32" width="12" height="1" fill="#C4C4C4" />
    <rect x="2.5" y="12.5" width="43" height="31" fill="white" stroke="#063D3A" />
    <rect x="3" y="15" width="40" height="28" fill="#99DBD6" />
    <rect x="1.5" y="4.5" width="45" height="8" fill="white" stroke="#063D3A" />
    <rect x="2" y="7" width="42" height="5" fill="#99DBD6" />
    <path
      d="M21 18V4H27V18L25.5 16.8333L24 18L22.5 16.8333L21 18Z"
      fill="#00A499"
      stroke="#063D3A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
