import React from 'react';

const ID = 'caret-down';

export const CaretDown: React.FC<{ title?: string }> = ({ title = 'Expand' }) => (
  <svg aria-labelledby={ID} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id={ID}>{title}</title>
    <path
      d="M8.37796 11.5636C8.17858 11.7938 7.82142 11.7938 7.62204 11.5636L3.52033 6.82733C3.2399 6.5035 3.46992 6 3.8983 6L12.1017 6C12.5301 6 12.7601 6.5035 12.4797 6.82733L8.37796 11.5636Z"
      fill="black"
    />
  </svg>
);
