import React from 'react';

export const StackedBoxes = () => (
  <svg width="64" height="61" viewBox="0 0 64 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M59.8588 42.5494H37.2706V60.6235H59.8588V42.5494Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2706 42.5494H25.9765V60.6235H37.2706V42.5494Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8235 47.0679H46.3059C45.8541 47.0679 45.5529 46.7666 45.5529 46.3148C45.5529 45.8629 45.8541 45.5617 46.3059 45.5617H50.8235C51.2753 45.5617 51.5765 45.8629 51.5765 46.3148C51.5765 46.7666 51.2753 47.0679 50.8235 47.0679Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.8588 4.89502H37.2706V22.9691H59.8588V4.89502Z"
        fill="#FFEABF"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8235 9.41352H46.3059C45.8541 9.41352 45.5529 9.11229 45.5529 8.66043C45.5529 8.20858 45.8541 7.90735 46.3059 7.90735H50.8235C51.2753 7.90735 51.5764 8.20858 51.5764 8.66043C51.5764 9.11229 51.2753 9.41352 50.8235 9.41352Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.3294 23.7222H29.7412V41.7963H52.3294V23.7222Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0941 23.7222H51.5765V41.7963H56.0941V23.7222Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2941 28.2407H38.7765C38.3247 28.2407 38.0235 27.9395 38.0235 27.4876C38.0235 27.0358 38.3247 26.7346 38.7765 26.7346H43.2941C43.7459 26.7346 44.0471 27.0358 44.0471 27.4876C44.0471 27.9395 43.7459 28.2407 43.2941 28.2407Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2706 42.5494V59.8704"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2706 23.7222H56.0941"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5765 41.7963V23.7222"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0941 42.5494H29.7412"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8912 15.56L19.1793 59.3167L26.5942 60.6241L34.3061 16.8673L26.8912 15.56Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5788 60.6235L33.0541 23.7222V42.5494H30.0424V60.6235H26.5788Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.32 59.1173L32 15.363"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.8588 4.89504L48.5647 0.376526"
        stroke="#FFEABF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0259 44.658L15.2847 48.5741V60.6235H12.7247V48.5741L10.4659 44.658H13.0259Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.88 44.658L29.1388 48.5741V60.6235H27.3318V48.5741L25.0729 44.658H26.88Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6565 60.6235H5.49647C4.74353 60.6235 4.14117 60.021 4.14117 59.2679V49.1012C4.14117 48.7247 4.29176 48.4235 4.51764 48.1222L6.02353 46.6914C6.24941 46.4654 6.62588 46.3148 7.00235 46.3148H32.2259C32.6024 46.3148 32.9035 46.4654 33.2047 46.6914L34.6353 48.1975C34.8612 48.4235 35.0118 48.8 35.0118 49.1766V59.3432C35.0118 60.021 34.4094 60.6235 33.6565 60.6235Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1388 60.6235V49.1012C29.1388 48.7247 29.2894 48.4235 29.5153 48.1222L30.9459 46.6914C31.1718 46.4654 31.5482 46.3148 31.9247 46.3148"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1388 50.5321V49.4778C29.1388 48.9506 28.7624 48.5741 28.2353 48.5741H5.12002C4.59296 48.5741 4.21649 48.9506 4.21649 49.4778"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0729 46.3148L22.8141 48.5741V60.6235H24.6212V48.5741L26.88 46.3148H25.0729Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.32 44.658L22.0612 48.5741V60.6235H24.6212V48.5741L26.88 44.658H24.32Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4659 46.3148L8.20703 48.5741V60.6235H10.767V48.5741L13.0259 46.3148H10.4659Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4659 44.658L8.20703 48.5741V60.6235H10.767V48.5741L13.0259 44.658H10.4659Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0729 44.658H11.8212H10.4659L10.9176 42.5494H26.4282L26.88 44.658H25.0729Z"
        fill="#F79C94"
        stroke="#F79C94"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1694 44.658H13.0259L10.767 48.5741V60.6235"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6212 60.6235V48.5741L26.88 44.658"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2259 46.3148V50.8333"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2259 50.8333L31.0212 53.695H33.4306L32.2259 50.8333Z"
        fill="#A1E6E0"
        stroke="#A1E6E0"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.6235 60.6234H0.376465"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="64" height="61" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
