import React from 'react';

export const Truck: React.FC = () => (
  <svg width="89" height="83" viewBox="0 0 89 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="89" height="83">
      <rect x="0.0549316" width="88" height="82.5" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M40.2096 10.1073H-42.7678V57.6996H40.2096V10.1073Z"
        fill="#00A499"
        stroke="#00A499"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6335 55.7364C78.6335 56.8072 77.723 57.6996 76.6304 57.6996H44.277C43.1844 57.6996 42.2739 56.8072 42.2739 55.7364V53.7732"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.8562 34.9146L77.4181 39.3763C78.1465 39.6738 78.6321 40.3877 78.6321 41.2205V43.8976"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6332 57.6998H-38.7007V61.6856H78.6332V57.6998Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-97.3982 69.5974H84.703"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9849 57.6996H74.8689C73.23 53.0593 68.6775 49.7874 63.3965 49.7874"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6332 59.663H-38.7007"
        stroke="#EBECED"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4579 69.5976C67.9166 69.5976 71.5311 66.0552 71.5311 61.6854C71.5311 57.3156 67.9166 53.7732 63.4579 53.7732C58.9992 53.7732 55.3848 57.3156 55.3848 61.6854C55.3848 66.0552 58.9992 69.5976 63.4579 69.5976Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4573 65.6715C65.7034 65.6715 67.5243 63.8869 67.5243 61.6856C67.5243 59.4843 65.7034 57.6998 63.4573 57.6998C61.2112 57.6998 59.3904 59.4843 59.3904 61.6856C59.3904 63.8869 61.2112 65.6715 63.4573 65.6715Z"
        fill="#EBECED"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4582 63.6487C64.5645 63.6487 65.4613 62.7698 65.4613 61.6855C65.4613 60.6013 64.5645 59.7224 63.4582 59.7224C62.3519 59.7224 61.4551 60.6013 61.4551 61.6855C61.4551 62.7698 62.3519 63.6487 63.4582 63.6487Z"
        fill="#EBECED"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6327 43.8382H76.6296V53.7731H78.6327V43.8382Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9937 45.8015H78.2077"
        stroke="#EBECED"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9937 47.7648H78.2077"
        stroke="#EBECED"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9937 49.7874H78.2077"
        stroke="#EBECED"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9937 51.7506H78.2077"
        stroke="#EBECED"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-42.7678 49.7874H63.4579"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.5798 31.5241L59.8771 21.8866C59.7557 21.5892 59.6343 21.2917 59.4522 20.9943H54.1713H45.2483V33.9037H59.8164H65.2187C64.4903 33.2493 63.944 32.4759 63.5798 31.5241Z"
        fill="#E8F6F5"
        stroke="#E8F6F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.8131 29.9174H57.3882V35.8664H57.8131C58.7236 35.8664 59.452 35.1525 59.452 34.2602V31.4641C59.3913 30.6312 58.6629 29.9174 57.8131 29.9174Z"
        fill="#00404D"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3882 35.8665H63.4582"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.6304 43.0054C76.6304 42.2915 76.1447 41.6966 75.477 41.5181L66.372 39.198C65.4008 38.96 64.3689 38.8411 63.337 38.8411H44.277C43.1844 38.8411 42.2739 39.7334 42.2739 40.8042V43.0054"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.1292 31.8805C66.2505 31.583 66.4327 31.2261 66.5541 30.9286"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.4041 33.0113C67.7076 32.9518 67.9504 32.8924 68.2539 32.7734"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.2126 49.7873V20.0422C42.2126 18.9713 43.1232 18.079 44.2158 18.079H54.1706C56.72 18.079 58.966 19.6257 59.8765 21.9459"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1709 20.9943C55.4456 20.9943 56.5989 21.7676 57.0238 22.898L60.7266 32.5354"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.7264 32.5944C60.9692 33.2488 60.4836 33.9031 59.8159 33.9031"
        stroke="#00404D"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
